<template>
    <base-scheda-titolo models-name="citta"
                        :model="model"
                        model-ref="la citta"
                        :titolo="testo.DENOMINAZIONE + ' (' + model.SIGLA + ')'"
    ></base-scheda-titolo>
</template>

<script>
    import BaseSchedaTitolo from '@/components/aaaProject/scheda/BaseSchedaTitolo';

    export default {
        name: 'SchedaCittaTitolo',
        components: {BaseSchedaTitolo},
        props: {model: Object, testo: Object}
    }
</script>

<style scoped>

</style>
