<template>
    <base-scheda models-name="citta">

        <template #header="{ model }">
            <scheda-citta-header :model="model"
                                 v-model="idLinguaFk"
                                 @finish="isLingueLoading = false"
            ></scheda-citta-header>
        </template>

        <template #titolo="{ model }">

            <loading-resouces v-if="isLingueLoading"
                              class="pt-4"
                              icon="fa-flag"
                              messaggio="Recupero delle lingue in corso"
            ></loading-resouces>

            <scheda-citta-titolo v-else-if="Boolean(idLinguaFk)"
                                 :model="model"
                                 :testo="getTesto(model)"
            ></scheda-citta-titolo>

            <no-data-available v-else models-name="lingue" type="lingue"></no-data-available>

        </template>

        <template #generale="{ model }" v-if="Boolean(idLinguaFk)">
            <scheda-citta-generale :model="model" :testo="getTesto(model)"></scheda-citta-generale>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import SchedaCittaHeader from '@/components/secure/citta/components/SchedaCittaHeader';
    import SchedaCittaTitolo from '@/components/secure/citta/components/SchedaCittaTitolo';
    import SchedaCittaGenerale from '@/components/secure/citta/components/SchedaCittaGenerale';

    export default {
        name: 'SchedaCitta',
        components: {
            SchedaCittaGenerale,
            SchedaCittaTitolo,
            SchedaCittaHeader,
            LoadingResouces,
            NoDataAvailable,
            BaseScheda
        },
        data: () => ({
            isLingueLoading: true,
            idLinguaFk: undefined
        }),
        methods: {
            getTesto(citta) {

                let testo = citta.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = citta.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = citta.testi[0];
                }

                return testo;

            }
        }
    }
</script>

<style scoped>

</style>
