<template>
    <base-scheda-generale :model="model" :items="items"></base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';

    export default {
        name: 'SchedaCittaGenerale',
        components: {BaseSchedaGenerale},
        props: {model: Object, testo: Object},
        computed: {
            items: function () {
                return [
                    {label: 'Denominazione', data: this.testo.DENOMINAZIONE},
                    {label: 'Regione', data: this.model.REGIONE},
                    {label: 'Provincia', data: this.model.PROVINCIA},
                    {label: 'Sigla', data: this.model.SIGLA},
                    {label: 'Ripartizione Geografica', data: this.model.RIPARTIZIONE_GEOGRAFICA},
                    {label: 'Coordinate', data: this.model.COORDINATE},
                    {label: 'Testi Associati', data: this.testiAssociati},
                    {label: 'Eventi Associati', data: this.model.EV_COUNT},
                    {label: 'Visualizzazioni', data: this.model.VISUALIZZAZIONI},
                    {label: 'empty', noLabel: true, data: ' '}
                ]
            },
            testiAssociati: function () {
                return this.model.testi.length + ' ( ' + this.model.testi.map(t => t.LNG_SIGLA).join(', ') + ' )';
            }
        }
    }
</script>

<style scoped>

</style>
